* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background: rgba(52, 153, 205, 0.1);
}

.mainRaised {
  z-index: 3;
  position: relative;
  background-color: white;
  margin: -90px 60px 0px;
  border-radius: 60px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  padding-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .mainRaised {
    margin: -90px 30px 0px;
  }
}

@media screen and (max-width: 480px) {
  .mainRaised {
    margin: -90px 20px 0px;
  }
}
